<template>
  <st-page :title="titleComputed" :backLink="backLink">
    <Loader v-if="isLoading"/>

    <template #toolbar>
      <st-button :callback="onDownload">{{ $t("DOCUMENT_TEMPLATE.FORM.DOWNLOAD_BUTTON") }}</st-button>
      <st-button variant="secondary" :callback="openSaveModal">{{
        $t("GENERAL.BUTTON.SAVE")
      }}</st-button>
    </template>

    <div class="control-pane" ref="controlPane">
      <b-row class="h-100">
        <div class="col-md-2 flat-list-container">
          <div class="flat-list-title px-0 mx-4">{{ $t("DOCUMENT_TEMPLATE.FORM.FIELDS_LIST_TITLE") }}</div>
          <div class="form-group mx-4">
            <label>{{ $t("DOCUMENT_TEMPLATE.FORM.SELECT_TYPE") }}</label>
            <b-form-radio-group
                size="lg"
                v-model="selectedType"
                :options="typeOptions"
                name="selectedType"
            ></b-form-radio-group>
          </div>

          <div class="form-group mx-4" v-if="selectedType === CONTROL_TYPE">
            <label>{{ $t("DOCUMENT_TEMPLATE.FORM.APPLICATION_TYPES_LABEL") }}</label>
            <st-autocomplete
              v-model="mainControlTypeId"
              name="main_control_type"
              :options="controlTypeOptions"
              valueField="id"
              textField="name"
              @change="onMainControlTypeChanged"
            >
            </st-autocomplete>
          </div>

          <div class="form-group mx-4" v-if="selectedType === APPLICATION_TYPE">
            <label>{{ $t("DOCUMENT_TEMPLATE.FORM.APPLICATION_TYPES_LABEL") }}</label>
            <st-autocomplete
              v-model="mainApplicationTypeId"
              name="main_application_type"
              :options="applicationTypesOptions"
              valueField="id"
              textField="name"
              @change="onMainApplicationTypeChanged"
            >
            </st-autocomplete>
          </div>

          <div v-if="hasRelatedApplicationTypes" class="form-group mx-4">
            <label>{{ $t("DOCUMENT_TEMPLATE.FORM.RELATED_APPLICATION_TYPES_LABEL") }}</label>
            <st-autocomplete
              v-model="relatedApplicationTypeId"
              name="related_application_type"
              :options="relatedApplicationTypesOptions"
              valueField="id"
              textField="name"
              @change="onRelatedApplicationTypeChanged"
            ></st-autocomplete>
          </div>

          <div class="form-group mx-4" v-if="selectedType === APPLICATION_TYPE">
            <label>{{ $t("DOCUMENT_TEMPLATE.FORM.FORM_TYPE_LABEL") }}</label>
            <b-form-group >
              <b-form-radio v-model="selectedFormType" :value="CITIZEN_FORM_TYPE" :disabled="disableCitizenType">{{ $t("DOCUMENT_TEMPLATE.FORM.CITIZEN_LABEL") }}</b-form-radio>
              <b-form-radio v-model="selectedFormType" :value="STAFF_FORM_TYPE">{{ $t("DOCUMENT_TEMPLATE.FORM.STAFF_LABEL") }}</b-form-radio>
            </b-form-group>
          </div>
          <ejs-listview
            id="flat-list"
            :dataSource="metaFields"
            :fields="fields"
            :select="onSelectField"
            showHeader='true'
            :height="listHeight"
          >
          </ejs-listview>
        </div>
        <div class="col-md-10 document-editor-container">
          <ejs-documenteditorcontainer
            id="container"
            ref="doceditcontainer"
            v-bind:toolbarClick="onToolbarClick"
            :enableToolbar="true"
            :height="pageHeight"
            :serviceUrl="syncfusionApiUrl"
          >
          </ejs-documenteditorcontainer>
        </div>
      </b-row>
    </div>
    <document-template-create-modal
      v-if="!record"
      ref='document-template-create-modal'
      @submit="submit"
      :loading="isLoadingCreate"
    />

    <document-template-edit-modal
      v-if="record"
      ref='document-template-edit-modal'
      @submit="submit"
      :loading="isLoadingEdit"
      :selectedItem="record"
    />
  </st-page>
</template>
<script>
import Vue from "vue";
import {
  DocumentEditorContainerPlugin,
  Toolbar,
} from "@syncfusion/ej2-vue-documenteditor";
import { ListViewPlugin } from "@syncfusion/ej2-vue-lists";
import { mapActions, mapGetters } from "vuex";

Vue.use(DocumentEditorContainerPlugin);
Vue.use(ListViewPlugin);

const START_TAG = "#";
const END_TAG = "#";
const GIS_INTERSECTIONS_METADATA_KEY = 'gis_intersections'; // TODO: somehow fetch this from BE

export default {
  name: 'DocumentTemplateForm',
  props: {
    id: String,
  },
  components: {
    DocumentTemplateCreateModal: () => import('@/modules/document-template/components/DocumentTemplateCreateModal.vue'),
    DocumentTemplateEditModal: () => import('@/modules/document-template/components/DocumentTemplateEditModal.vue'),
  },
  data() {
    const CITIZEN_FORM_TYPE = 'CITIZEN';
    const STAFF_FORM_TYPE = 'STAFF';
    const APPLICATION_TYPE = 'application';
    const CONTROL_TYPE = 'control';

    return {
      metaFields: [],
      metaFieldsChildren: [],
      systemMetaFields: [],
      systemControlMetaFields: [],
      fields: { groupBy: "category", tooltip: "tooltip" },
      toolbarItems: [
        "New",
        "Open",
        "Separator",
        "Undo",
        "Redo",
        "Separator",
        "Table",
        "Header",
        "Footer",
        "Break",
        "Separator",
        "FormFields",
        "PageNumber",
        {
          prefixIcon: "e-btn-icon e-de-ctnr-pagenumber e-icons e-icon-left",
          tooltipText: "Add total number of pages.",
          text: "Pages",
          id: "Pages"
        },
        "PageSetup",
      ],
      applicationTypesOptions: [],
      relatedApplicationTypesOptions: [],
      mainApplicationTypeId: null,
      mainApplicationType: null,
      relatedApplicationTypeId: null,
      selectedApplicationType: null,
      formFields: {},
      pageHeight: 0,
      listHeight: 0,
      CITIZEN_FORM_TYPE,
      STAFF_FORM_TYPE,
      selectedFormType: CITIZEN_FORM_TYPE,
      controlTypeOptions: [],
      selectedControlType: null,
      mainControlTypeId: null,
      selectedType: null,
      APPLICATION_TYPE,
      CONTROL_TYPE,
      typeOptions: [{
        value: APPLICATION_TYPE,
        text: this.$t("DOCUMENT_TEMPLATE.FORM.APPLICATION_TYPE")
      }, {
        value: CONTROL_TYPE,
        text: this.$t("DOCUMENT_TEMPLATE.FORM.CONTROL_TYPE")
      }],
      disableCitizenType: false,
    };
  },
  provide() {
    return {
      DocumentEditorContainer: [Toolbar],
    };
  },
  computed: {
    ...mapGetters({
      record: 'documentTemplate/form/record',
      loading: 'shared/loading',
      file: 'documentTemplate/form/file',
    }),
    isLoadingCreate() {
      return !!this.loading['documentTemplate/create']
        || !!this.loading['documentTemplate/fileUpload'];
    },
    isLoadingEdit() {
      return !!this.loading['documentTemplate/update']
        || !!this.loading['documentTemplate/fileUpload'];
    },
    isLoadingFind() {
      return !!this.loading['documentTemplate/find'];
    },
    isLoadingForm() {
      return !!this.loading['documentTemplate/findForm'];
    },
    isLoadingCollectMetadata() {
      return !!this.loading['documentTemplate/collectMetadata'];
    },
    isLoadingCollectGisMetadata() {
      return !!this.loading['documentTemplate/collectGisMetadata']
    },
    isLoadingSystemMetadata() {
      return !!this.loading['documentTemplate/fetchSystemMetadata']
    },
    isLoading() {
      return this.isLoadingFind
        || this.isLoadingForm
        || this.isLoadingCollectMetadata
        || this.isLoadingSystemMetadata
        || this.isLoadingCollectGisMetadata;
    },
    titleComputed() {
      if (!this.id) return this.$t('DOCUMENT_TEMPLATE.FORM.TITLE');
      return this?.record?.name;
    },
    hasRelatedApplicationTypes() {
      return this.selectedApplicationType && this.relatedApplicationTypesOptions?.length;
    },
    syncfusionApiUrl() {
      return window.VUE_APP_SYNCFUSION_API_URL;
    },
    metaPrefix() {
      return (this.selectedType === this.APPLICATION_TYPE) ? this.selectedApplicationType.key : this.selectedControlType.name.toUpperCase()
    },
    backLink() {
        return  { name: 'documentTemplate' } ;
    },
  },
  methods: {
    ...mapActions({
      doFetchApplicationTypes: "documentTemplate/form/fetchApplicationTypes",
      doFetchForms: "documentTemplate/form/fetchForms",
      doFindForm: "documentTemplate/form/findForm",
      doFileUpload: "documentTemplate/form/fileUpload",
      doCreate: "documentTemplate/form/create",
      doUpdate: "documentTemplate/form/update",
      doFind: 'documentTemplate/form/find',
      doClear: 'documentTemplate/form/clear',
      doCollectMetadata: 'documentTemplate/form/collectMetadata',
      doCollectGisMetadata: 'documentTemplate/form/collectGisMetadata',
      doFetchSystemMetadata: 'documentTemplate/form/fetchSystemMetadata',
      doFetchControlTypes: "documentTemplate/form/fetchControlTypes",
      doFetchControlSystemMetadata: 'documentTemplate/form/fetchControlSystemMetadata',
    }),
    async onMainApplicationTypeChanged(mainApplicationTypeId) {
      if (mainApplicationTypeId) {
        const mainApplicationType = this.applicationTypesOptions
          .find(el => el.id === mainApplicationTypeId);
        this.relatedApplicationTypesOptions = this.applicationTypesOptions
          .filter(el => mainApplicationType.related_application_type_ids.includes(el.id));
        this.mainApplicationType = mainApplicationType;
        this.selectedApplicationType = mainApplicationType;
      } else {
        this.selectedApplicationType = null;
        this.relatedApplicationTypeId = null;
        this.relatedApplicationTypesOptions = [];
      }
    },
    onRelatedApplicationTypeChanged(relatedApplicationTypeId) {
      if (relatedApplicationTypeId) {
        const relatedApplicationType = this.relatedApplicationTypesOptions
          .find(el => el.id === relatedApplicationTypeId);
        this.selectedApplicationType = relatedApplicationType;
      } else {
        this.selectedApplicationType = this.mainApplicationType;
      }
    },
    async onMainControlTypeChanged(mainControlTypeId) {
      if (mainControlTypeId) {
        this.selectedControlType = this.controlTypeOptions.find(el => el.id === mainControlTypeId);
      } else {
        this.selectedControlType = null;
      }
    },
    onDownload() {
      const format = 'Docx';
      this.$refs.doceditcontainer.ej2Instances.documentEditor.save(
        !this.id
          ? "SampleDocumentTemplate"
          : this.record.name,
        format
      );
    },
    onToolbarClick(args) {
      switch (args.item.id) {
        case 'Pages':
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.insertField('NUMPAGES \* MERGEFORMAT', 1);
          break;
      }
    },
    insertPlaceholder(fieldMeta) {
      const fieldName = fieldMeta
        .replace(/\n/g, "")
        .replace(/\r/g, "")
        .replace(/\r\n/g, "");
      const fieldCode = "MERGEFIELD  " + fieldName + "  \\* MERGEFORMAT ";
      this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.insertField(
        fieldCode,
        fieldName
      );
    },
    insertField(fieldMeta, fieldType, parentFieldName) {
      const fieldName = fieldMeta
        .replace(/\n/g, "")
        .replace(/\r/g, "")
        .replace(/\r\n/g, "");

      let insertedField = '';
      if (fieldType === 'system') {
        insertedField = `${START_TAG}=${this.metaPrefix}.${fieldName}${END_TAG}`;
      } else if (fieldType === 'property' && parentFieldName) {
        insertedField = `${START_TAG}=${this.metaPrefix}.${this.selectedFormType}.${parentFieldName}.${fieldName}${END_TAG}`;
      } else {
        insertedField = `${START_TAG}=${this.metaPrefix}.${this.selectedFormType}.${fieldName}${END_TAG}`;
      }

      const fieldCode = "MERGEFIELD  " + fieldName + "  \\* MERGEFORMAT ";
      this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.insertField(
        fieldCode,
        insertedField
      );
      this.$refs.doceditcontainer.ej2Instances.documentEditor.focusIn();
    },
    insertList(fieldMeta) {
      //const prefix = this.selectedApplicationType.key;
      let fieldName = fieldMeta
        .replace(/\n/g, "")
        .replace(/\r/g, "")
        .replace(/\r\n/g, "");

      const insertedList = `${START_TAG}FOR item IN ${this.metaPrefix}.${this.selectedFormType}.${fieldName}${END_TAG}\n${START_TAG}END-FOR item${END_TAG}`;
      const fieldCode = "MERGEFIELD  " + fieldName + "  \\* MERGEFORMAT ";
      this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.insertField(
        fieldCode,
        insertedList
      );
      this.$refs.doceditcontainer.ej2Instances.documentEditor.focusIn();
    },
    insertNestedList(fieldMeta, parentFieldName) {
        let fieldName = fieldMeta
            .replace(/\n/g, "")
            .replace(/\r/g, "")
            .replace(/\r\n/g, "");

        const fieldAccessor = `${parentFieldName ?? '$item'}.${fieldName}`;
        const insertedList = `${START_TAG}FOR ${fieldName} IN ${fieldAccessor}${END_TAG}\n${START_TAG}END-FOR ${fieldName}${END_TAG}`;
        const fieldCode = "MERGEFIELD  " + fieldName + "  \\* MERGEFORMAT ";
        this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.insertField(
            fieldCode,
            insertedList
        );
        this.$refs.doceditcontainer.ej2Instances.documentEditor.focusIn();
    },
    insertChild(fieldMeta, parentFieldName) {
      const fieldName = fieldMeta
        .replace(/\n/g, "")
        .replace(/\r/g, "")
        .replace(/\r\n/g, "");

      const iteratorName = parentFieldName ?? '$item';
      const insertedField = `${START_TAG}INS ${iteratorName}.${fieldName}${END_TAG}`;
      const fieldCode = "MERGEFIELD  " + fieldName + "  \\* MERGEFORMAT ";
      this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.insertField(
        fieldCode,
        insertedField
      );
      this.$refs.doceditcontainer.ej2Instances.documentEditor.focusIn();
    },
    insertCheckbox(fieldMeta) {
      //const prefix = this.selectedApplicationType.key;
      let fieldName = fieldMeta
        .replace(/\n/g, "")
        .replace(/\r/g, "")
        .replace(/\r\n/g, "");

      let parentField = `options.${fieldName}`;

      const insertedList = `${START_TAG}FOR item IN ${this.metaPrefix}.${this.selectedFormType}.${parentField}${END_TAG}
      ${START_TAG}=${this.metaPrefix}.${this.selectedFormType}.${fieldName}.includes($item) ? '[X] ' : '[ ] '${END_TAG}${START_TAG} INS $item ${END_TAG}
      ${START_TAG}END-FOR item${END_TAG}`;
      const fieldCode = "MERGEFIELD  " + fieldName + "  \\* MERGEFORMAT ";

      const formattedList = insertedList.replace(/\s\s+/g, " ");
      this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.insertField(
        fieldCode,
        formattedList
      );
      this.$refs.doceditcontainer.ej2Instances.documentEditor.focusIn();
    },
    insertHTML(fieldMeta) {
      const fieldName = fieldMeta
        .replace(/\n/g, "")
        .replace(/\r/g, "")
        .replace(/\r\n/g, "");

      const value = `${this.metaPrefix}.${this.selectedFormType}.${fieldName}`;
      let insertedField = `${START_TAG}IF ${value} ${END_TAG}`;
      insertedField += START_TAG + `HTML \"<html><body>\" + ${value} + \"</body></html>\"` + END_TAG;
      insertedField += `${START_TAG}END-IF${END_TAG}`;

      const fieldCode = "MERGEFIELD  " + fieldName + "  \\* MERGEFORMAT ";
      this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.insertField(
        fieldCode,
        insertedField
      );
      this.$refs.doceditcontainer.ej2Instances.documentEditor.focusIn();
    },
    insertMetadata(text, type, parentFieldName) {
      switch (type) {
        case 'beneficiary-list':
        case 'real-estate-list':
        case 'decision-list':
        case 'locality-list':
        case 'buildings-list':
        case 'urbanism-regulations-list':
          this.insertList(text);
          break;
        case 'nestedarray':
          this.insertNestedList(text, parentFieldName);
          break;
        case 'skippedobject':
        case 'nestedobject':
          break;
        case 'child':
          this.insertChild(text, parentFieldName);
          break;
        case 'checkbox':
          this.insertCheckbox(text);
          break;
        case 'sign':
          this.insertPlaceholder(text);
          break;
        case 'related-application':
        case 'inline':
          break;
        case 'property':
          this.insertField(text, type, parentFieldName);
          break;
        case 'editor':
        case 'html':
          // uncomment on master
          this.insertHTML(text);
          break;
        default:
          this.insertField(text, type);
          break;
      }
    },
    insertControlMetadata(text, type, parentFieldName) {
      switch (type) {
        case 'realty-list':
        case 'witness-list':
          this.insertList(text);
          break;
        case 'child':
          this.insertChild(text);
          break;
        case 'checkbox':
          this.insertCheckbox(text);
          break;
        case 'inline':
          break;
        case 'property':
          this.insertField(text, type, parentFieldName);
          break;
        default:
          this.insertField(text, type);
          break;
      }
    },
    onSelectField(args) {
      let fieldName = args.text;
      const fieldType = args.data.type;
      const parentFieldName = args.data?.parentField;

      if (fieldType === 'sign') {
        fieldName = args.data.key;
      }

      args.item.classList.remove("e-active");
      this.selectInsertMetadataMethod(fieldName, fieldType, parentFieldName);
    },
    openSaveModal() {
      if (this.id) {
        this.$refs['document-template-edit-modal'].show();
      } else {
        this.$refs['document-template-create-modal'].show();
      }
    },
    async submit(item) {
      const doc = await this.$refs.doceditcontainer.ej2Instances.documentEditor.saveAsBlob("Docx");
      const sfdt = await this.$refs.doceditcontainer.ej2Instances.documentEditor.saveAsBlob("Sfdt");
      const jsonFile = new Blob([await sfdt.text()], { type: "plain/text" });

      let response;

      if (this.id) {
        response = await this.doUpdate({
          id: this.id,
          values: {
            ...item,
          }
        });
      } else {
        response = await this.doCreate({
          ...item,
          files: {
            sfdt: "",
            docx: "",
          },
        });
      }

      const { data, uploadAccessToken } = response;
      const documentTemplate = data;
      const formData = new FormData();
      formData.append("files", doc, 'Cerere urbanism docx');
      formData.append("files", jsonFile, 'Cerere urbanism sfdt');

      this.doFileUpload({
        formData,
        uploadToken: uploadAccessToken,
      })
      .then((data) => {
        this.doUpdate({
          id: documentTemplate.id,
          values: {
            files: {
              docx: data[0].id,
              sfdt: data[1].id,
            },
          },
        }).
        then(() => {
            if (this.id) {
                this.$refs['document-template-edit-modal'].hide();
            } else {
                this.$router.push({ name: 'documentTemplate' });
            }
        });
      });
    },
    createMetaField(field, parent, isRootNode) {
      return {
        text: field.name,
        tooltip: field.name,
        htmlAttributes: { draggable: true },
        category: this.$t(`GENERAL.${this.selectedFormType}`),
        type: field.type,
        id: this.generateRandomId(),
        child: field.child ? field.child.map(childField => this.createMetaField(
            childField,
            isRootNode && childField.type !== 'property' ? null : childField.parentField || field.name,
            false,
        )) : [],
        parentField: field.parentField ?? parent,
      };
    },
    async updateMetaFields(applicationType) {
      if (applicationType) {
        let formId;
        let rejectedFormId = "";

        if (this.selectedFormType === this.CITIZEN_FORM_TYPE) {
          formId = applicationType.form_citizen_id;
        } else {
          formId = applicationType.form_staff_id;
          rejectedFormId = applicationType?.reject_form_staff_id ?? "";
        }

        const form = await this.doFindForm(formId);


        this.metaFields = form.fields_map.map(field => this.createMetaField(field, null, true));

        //system meta have to appear all the time
        this.metaFields = [ ...this.systemMetaFields, ...this.metaFields];

        if (rejectedFormId) {
          const form = await this.doFindForm(rejectedFormId);
          const rejectedMetaFields = form.fields_map.filter((field) => {
            const isDuplicate = this.metaFields.find((el) => el.text === field.name);
            if (!isDuplicate) {
              return field;
            }
          }).map((field) => ({
            text: field.name,
            tooltip: field.name,
            htmlAttributes: { draggable: true },
            category: this.$t(`GENERAL.${this.selectedFormType}`),
            type: field.type,
            id: this.generateRandomId(),
            child: field.child.map(field => ({
              text: field.name,
              tooltip: field.name,
              htmlAttributes: { draggable: true },
              type: field.type,
              id: this.generateRandomId(),
              parentField: field.parentField ?? field.name,
            })),
          }));

          this.metaFields = [...rejectedMetaFields, ...this.metaFields];
        }

        if (this.selectedFormType === this.STAFF_FORM_TYPE) {
          let metaWorkflow = await this.doCollectMetadata(applicationType.workflow_id);
          metaWorkflow = metaWorkflow.map((meta) => ({
            text: meta.name,
            tooltip: meta.name,
            htmlAttributes: { draggable: true },
            category: meta?.type === 'sign' ? this.$t('GENERAL.SIGN') : this.$t('GENERAL.SYSTEM'),
            type: meta.type,
            key: meta.sign_placeholder,
            id: this.generateRandomId(),
          }));

          this.metaFields = [ ...this.metaFields, ...metaWorkflow ];

          // GIS Intersections meta
          let gisMetaWorkflow = await this.doCollectGisMetadata(applicationType.workflow_id);

          if(gisMetaWorkflow?.length) {
            const parentGisMetaWorkflow = {
              text: this.$t('GENERAL.GIS_INTERSECTIONS'),
              tooltip: this.$t('GENERAL.GIS_INTERSECTIONS'),
              htmlAttributes: { draggable: false },
              category: this.$t('GENERAL.GIS_INTERSECTIONS'),
              type: 'nestedobject',
              id: this.generateRandomId(),
            };
            gisMetaWorkflow = gisMetaWorkflow.map((meta) => ({
              text: meta.name,
              tooltip: `${meta.layer_name}/${meta.sublayer_name}/${meta.gis_attribute_name}`,
              category: this.$t('GENERAL.GIS_INTERSECTIONS_METADATAS'),
              type: 'nestedobject',
              id: this.generateRandomId(),
              parentField: GIS_INTERSECTIONS_METADATA_KEY,
              child: Object.keys(meta.child).map (v => {
                return {
                  text: meta.child[v].name,
                  tooltip: meta.child[v].name,
                  type: meta.child[v].type ?? 'property',
                  category: this.$t('GENERAL.GIS_INTERSECTIONS_METADATAS'),
                  id: this.generateRandomId(),
                  parentField: meta.child[v].type !== 'nestedarray'?
                    `${GIS_INTERSECTIONS_METADATA_KEY}.${meta.name}`:
                    `${this.metaPrefix}.${this.selectedFormType}.${GIS_INTERSECTIONS_METADATA_KEY}.${meta.name}`,
                  htmlAttributes: { draggable: meta.child[v].type !== 'nestedarray' },
                  child: (meta.child[v]?.child ?? [])?.map( c => {
                    return {
                      text: c.name,
                      tooltip: c.name,
                      type: c.type,
                      category: this.$t('GENERAL.GIS_INTERSECTIONS_RESULTS'),
                      id: this.generateRandomId(),
                      parentField: c.type !== 'child' ?
                        `${GIS_INTERSECTIONS_METADATA_KEY}.${meta.name}.${meta.child[v].name}`:
                        `$${meta.child[v].name}`
                    }
                  })
                }
              })
            }));

            parentGisMetaWorkflow["child"] = gisMetaWorkflow;

            this.metaFields = [ ...this.metaFields, parentGisMetaWorkflow ];
          }
        }
      }

      //grids/sections/dividers should not be included in metafields
      this.metaFields = this.metaFields?.filter((el) => el.text !== undefined);

      //separate child properties to fix drag/drop error
      let relatedMeta = this.metaFields.map((el) => el?.child ?? []).filter((el) => el?.length);
      for (let i=0; i < relatedMeta.length; i++) {
        this.metaFieldsChildren = [...this.metaFieldsChildren, ...relatedMeta[i]];
      }
    },
    generateRandomId() {
      return `id${Math.random().toString(36).substr(2, 10)}`;
    },
    selectInsertMetadataMethod(text, type, parentField) {
      if (this.selectedType === this.APPLICATION_TYPE) {
        this.insertMetadata(text, type, parentField);
      } else {
        this.insertControlMetadata(text, type, parentField);
      }
    },
    async updateControlMetaFields(controlType) {
      if (controlType) {
        const form = await this.doFindForm(controlType.form_id);

        this.metaFields = form.fields_map.map(field => ({
          text: field.name,
          htmlAttributes: { draggable: true },
          category: this.$t(`GENERAL.${this.selectedFormType}`),
          type: field.type,
          id: this.generateRandomId(),
          child: field.child.map((childField) => ({
            text: childField.name,
            htmlAttributes: { draggable: true },
            type: childField.type,
            parentField: field.name,
            id: this.generateRandomId(),
          })),
        }));
      }

      //grids/sections/dividers should not be included in metafields
      this.metaFields = this.metaFields?.filter((el) => el.text !== undefined);

      //system meta have to appear all the time
      this.metaFields = [ ...this.systemControlMetaFields, ...this.metaFields];

      //separate child properties to fix drag/drop error
      let relatedMeta = this.metaFields.map((el) => el?.child ?? []).filter((el) => el?.length);
      for (let i=0; i < relatedMeta.length; i++) {
        this.metaFieldsChildren = [...this.metaFieldsChildren, ...relatedMeta[i]];
      }
    }
  },
  watch: {
    selectedApplicationType: {
      handler: async function(value) {
        if (value && !value.form_citizen_id) {
          this.disableCitizenType = true;
          this.selectedFormType = this.STAFF_FORM_TYPE;
        } else {
          this.disableCitizenType = false;
          this.selectedFormType = this.CITIZEN_FORM_TYPE;
        }
        await this.updateMetaFields(value);
      }
    },
    selectedFormType: {
      handler: async function(value) {
        if (value) {
          await this.updateMetaFields(this.selectedApplicationType);
        }
      }
    },
    selectedType: {
      handler: function(value) {
        this.selectedFormType = (value === this.CONTROL_TYPE) ? this.STAFF_FORM_TYPE : this.CITIZEN_FORM_TYPE;
        this.selectedApplicationType = null;
        this.relatedApplicationTypeId = null;
        this.relatedApplicationTypesOptions = [];
        this.selectedControlType = null;
        this.metaFields = [];
        this.metaFieldsChildren = [];
        this.mainControlTypeId = null;
        this.mainApplicationTypeId = null;
      }
    },
    selectedControlType: {
      handler: async function(value) {
        await this.updateControlMetaFields(value);
      }
    },
  },
  async created() {
    this.applicationTypesOptions = await this.doFetchApplicationTypes();
    this.controlTypeOptions = await this.doFetchControlTypes();

    const systemMetadataOptions = await this.doFetchSystemMetadata();
    if (systemMetadataOptions.length) {
        this.systemMetaFields = systemMetadataOptions.map((meta) => ({
          text: meta.name,
          htmlAttributes: { draggable: true },
          category: meta?.category === 'sign' ? this.$t('GENERAL.SIGN') : this.$t('GENERAL.SYSTEM'),
          id: this.generateRandomId(),
          type: meta?.type === 'sign' ? 'sign' : 'system',
          key: meta?.key,
        }));
    }

    const systemControlMetadataOptions = await this.doFetchControlSystemMetadata();
    if (systemControlMetadataOptions.length) {
        this.systemControlMetaFields = systemControlMetadataOptions.map((meta) => ({
          text: meta.name,
          htmlAttributes: { draggable: true },
          category: this.$t('GENERAL.SYSTEM'),
          id: this.generateRandomId(),
          type: 'system',
          key: meta?.key
        }));
    }

    if (this.id) {
      await this.doFind(this.id);
      const obj = this.$refs.doceditcontainer.ej2Instances.documentEditor;
      obj.open(JSON.stringify(this.file));
    } else {
      await this.doClear();
    }
  },
  mounted() {
    // Remove syncfusion license alert text
    const spans = document.getElementsByTagName("span");

    for (let i = 0; i < spans.length; i++) {
      if (spans[i].innerText?.includes('This application was built using a trial version of Syncfusion Essential Studio.')
        || spans[i].innerText?.includes('The included Syncfusion license key is invalid.')) {
        const div = spans[i].parentElement;
        if (div) {
          div.remove();
        }
      }
    }
    // Remove syncfusion popup
    const as = document.getElementsByTagName("a");
    for (let i = 0; i < as.length; i++) {
      if (as[i].innerText?.includes('Claim your FREE account')) {
        const div = as[i].parentElement.parentElement.parentElement;
        if (div) {
          div.remove();
        }
      }
    }

    const h = this.$refs.controlPane.clientHeight;
    this.pageHeight = h - 35;
    this.listHeight = h - 150;

    this.$nextTick(function () {
      this.$refs.doceditcontainer.ej2Instances.documentEditor.focusIn();
    });

    const items = this.toolbarItems;
    this.$refs.doceditcontainer.ej2Instances.toolbarItems = items;
    document
      .getElementById("flat-list")
      .addEventListener("dragstart", (event) => {
        let dataUid = event.target.getAttribute('data-uid');
        let metaValue = this.metaFields.find((el) => el.id === dataUid);
        if (!metaValue) {
          metaValue = this.metaFieldsChildren.find((el) => el.id === dataUid);
        }

        event.dataTransfer.setData("data", JSON.stringify(metaValue));
        event.target.classList.add("de-drag-target");
      });

    // Prevent default drag over for document editor element
    document
      .getElementById("container")
      .addEventListener("dragover", (event) => {
        event.preventDefault();
      });

    // Drop Event for document editor element
    document.getElementById("container").addEventListener("drop", (e) => {
      const data = e.dataTransfer.getData("data");
      let { text, type, key, parentField } = JSON.parse(data);

      this.$refs.doceditcontainer.ej2Instances.documentEditor.selection.select({
        x: e.offsetX,
        y: e.offsetY,
        extend: false,
      });

      if (type === 'sign') {
        text = key;
      }

      this.selectInsertMetadataMethod(text, type, parentField);
    });

    document.addEventListener("dragend", (event) => {
      if (event.target.classList.contains("de-drag-target")) {
        event.target.classList.remove("de-drag-target");
      }
    });
  },
};
</script>
<style>
@import '~@syncfusion/ej2-base/styles/bootstrap.css';
@import '~@syncfusion/ej2-buttons/styles/bootstrap.css';
@import '~@syncfusion/ej2-inputs/styles/bootstrap.css';
@import '~@syncfusion/ej2-popups/styles/bootstrap.css';
@import '~@syncfusion/ej2-lists/styles/bootstrap.css';
@import '~@syncfusion/ej2-navigations/styles/bootstrap.css';
@import '~@syncfusion/ej2-splitbuttons/styles/bootstrap.css';
@import '~@syncfusion/ej2-dropdowns/styles/bootstrap.css';
@import "~@syncfusion/ej2-vue-documenteditor/styles/bootstrap.css";

</style>
<style scoped lang="scss" src="@/assets/sass/pages/document-template/document-template.scss"></style>
